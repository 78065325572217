import { React, useState } from "react";
import axios from "axios";
import { adminLogin } from "../adminReducer";
import { useDispatch } from "react-redux";
import { base_url } from "../constant";

function Login() {
    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");
    const dispatch = useDispatch();

    const handleLogin = () => {
        const user = {
            email: email,
            pwd: pwd,
        };

        axios.post(base_url + "members/admin", { user }).then((res) => {
            console.log("result", res.data.result);
            if (res.data.result == 1) {
                dispatch(adminLogin());
            }
        });
    };
    return (
        <div className="flex items-center justify-center mt-20 bg-gray-100">
            <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
                <h3 className="text-2xl font-bold text-center">
                    Login to your account
                </h3>
                <div>
                    <div className="mt-4">
                        <div>
                            <label className="block" for="email">
                                Email
                            </label>
                            <input
                                type="text"
                                placeholder="Email"
                                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block">Password</label>
                            <input
                                type="password"
                                placeholder="Password"
                                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                onChange={(e) => setPwd(e.target.value)}
                            />
                        </div>
                        <div className="flex">
                            <button
                                onClick={handleLogin}
                                className="px-6 py-2 mt-4 w-full text-white bg-blue-600 rounded-sm hover:bg-blue-900"
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
