import { React, useState } from "react";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import circle from "../assets/img/circle.png";
import cross from "../assets/img/cross.png";
import triangle from "../assets/img/triangle.png";
import axios from "axios";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { base_url } from "../constant";

export default function AttendanceTable(props) {
    const [selectedOption, SetSelectedOption] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState("");
    const [memberId, setMemberId] = useState("");

    const customStyles = {
        dropdownIndicator: () => ({
            width: 20,
        }),
    };
    const options = [
        {
            value: "0",
            label: <img src={triangle} className="w-4 h-4 inline-block mr-1" />,
        },
        {
            value: "1",
            label: <img src={circle} className="w-4 h-4 inline-block mr-1" />,
        },
        {
            value: "2",
            label: <img src={cross} className="w-4 h-4 inline-block mr-1" />,
        },
    ];

    const handleComment = (e) => {
        setComment(e.target.value);
    };
    const handleCommentRegister = (e) => {
        toast.success("コメントが変更されました。", {
            position: "top-right",
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
        const commentEl = document.getElementById(memberId);
        commentEl.innerHTML = comment;
        setShowModal(false);
        let data = {
            comment: comment,
            id: memberId,
        };
        axios.post(base_url + "attendance/updateComment", data).then((res) => {
            console.log(res);
        });
    };
    const handleChange = (e, id) => {
        console.log(e.value);
        console.log(id);
        const data = {
            id: id,
            type: e.value,
        };
        axios
            .post(base_url + "attendance/changeStatus", { data })
            .then((res) => {
                console.log(res.data);
            });
    };
    const attendance_element = (type, id) => {
        return (
            <Select
                defaultValue={options[type]}
                options={options}
                onChange={(e) => handleChange(e, id)}
                styles={customStyles}
                className=" w-16 mx-auto rounded text-gray-700  border border-gray-300 bg-white  shadow-sm  focus:border-primary-500"
            />
        );
    };
    return (
        <>
            <table className="min-w-full  border mt-10 ">
                <thead className="border">
                    <tr>
                        <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left w-24"
                        ></th>
                        {props.headers.map((header, i) => {
                            return (
                                <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                    key={i}
                                >
                                    <div className="flex flex-col justify-center items-center">
                                        <p>{header["meeting_time"]}</p>
                                        <p className="text-bold">
                                            {header["title"]}
                                        </p>
                                        <p className="text-[12px] text-blue-gray-500">
                                            {header["detail"]}
                                        </p>
                                    </div>
                                </th>
                            );
                        })}
                        <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-center"
                        >
                            コメント
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.members.map((member, i) => {
                        let member_id = member["id"];
                        let attendance_array = props.attendances[member_id];
                        return (
                            <tr className="border-b">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center font-medium text-gray-900 ">
                                    <button
                                        onClick={(e) => {
                                            setShowModal(true);
                                            if (member.comment == null) {
                                                setComment("");
                                            } else {
                                                setComment(member.comment);
                                            }
                                            setMemberId(member["id"]);
                                        }}
                                    >
                                        {member.name}
                                    </button>
                                </td>
                                {attendance_array.map((attendance, i) => {
                                    return (
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {attendance_element(
                                                attendance[
                                                    "attendance_type_id"
                                                ],
                                                attendance["id"]
                                            )}
                                        </td>
                                    );
                                })}
                                <td
                                    id={member.id}
                                    className="px-6 py-4 whitespace-nowrap text-sm  text-center font-medium text-gray-900"
                                >
                                    {member.comment}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <Modal
                size="lg"
                active={showModal}
                toggler={() => setShowModal(false)}
            >
                <ModalHeader toggler={() => setShowModal(false)}>
                    コメント入力
                </ModalHeader>
                <ModalBody>
                    <div className="flex flex-col w-96">
                        <div className="md:mb-4">
                            <Input
                                id="name"
                                type="text"
                                color="lightBlue"
                                size="regular"
                                outline={true}
                                placeholder="コメント"
                                onChange={handleComment}
                                value={comment}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="green"
                        ripple="light"
                        onClick={handleCommentRegister}
                    >
                        保存
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
