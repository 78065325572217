import { useState } from "react";
import { NavLink } from "react-router-dom";
import UserNavbar from "./UserNavbar";
import Icon from "@material-tailwind/react/Icon";
import H6 from "@material-tailwind/react/Heading6";

export default function Sidebar() {
    const [showSidebar, setShowSidebar] = useState("-left-64");
    return (
        <>
            <UserNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
        </>
    );
}
