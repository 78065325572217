import { Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import UserSidebar from "./components/UserSidebar";
import Events from "./pages/Events";
import Footer from "./components/Footer";
import Members from "./pages/Members";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { useSelector } from "react-redux";

// Tailwind CSS Style Sheet
import "@material-tailwind/react/tailwind.css";
function App() {
    const isAdmin = useSelector((state) => state.admin.isAdmin);
    return (
        <>
            {isAdmin === true ? <Sidebar /> : <UserSidebar />}
            {console.log("store is changed")}
            <div className={isAdmin === true && "md:ml-64"}>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/home" element={<Home />} />
                    <Route exact path="/events" element={<Events />} />
                    <Route exact path="/members" element={<Members />} />
                    <Route
                        path="/admin"
                        element={isAdmin === true ? <Events /> : <Login />}
                    />
                </Routes>
                <Footer />
            </div>
        </>
    );
}

export default App;
