import { useLocation } from "react-router-dom";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";
import NavbarInput from "@material-tailwind/react/NavbarInput";
import Image from "@material-tailwind/react/Image";
import Dropdown from "@material-tailwind/react/Dropdown";
import DropdownItem from "@material-tailwind/react/DropdownItem";
import ProfilePicture from "../assets/img/team-1-800x800.jpg";
import logo from "../assets/img/logo.png";
export default function UserNavbar({ showSidebar, setShowSidebar }) {
    const location = useLocation().pathname;
    const location_array = {
        events: "イベント",
        members: "メンバ",
    };
    const locationSwitch = (location) => {
        console.log(location);
        switch (location) {
            case "/":
                return "出席状況";
            case "/events":
                return "イベント";
            case "/home":
                return "出席状況";
            default:
                return "メンバ";
        }
    };

    return (
        <nav className="bg-light-blue-500 py-6 px-3">
            <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
                <div className="flex  items-center w-full">
                    <img src={logo} className=" w-16 h-14 mr-3" />
                    <h2 className="uppercase text-white text-[24px] tracking-wider mt-1">
                        出席システム
                    </h2>
                </div>
            </div>
        </nav>
    );
}
