import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";
import Textarea from "@material-tailwind/react/Textarea";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import axios from "axios";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import { SymbolDisplayPartKind } from "typescript";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import "react-data-table-component-extensions/dist/index.css";
import { base_url } from "../constant";

export default function Dashboard() {
    const [dateState, setDateState] = useState("");
    const [event, setEvent] = useState("");
    const [title, setTitle] = useState("");
    const [detail, setDetail] = useState("");
    const [id, setId] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [date, setDate] = useState("");
    const [data, setData] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [time, setTime] = useState("");
    const [meetingDate, setMeetingDate] = useState("");

    const [flag, setFlag] = useState(false);
    const columns = [
        {
            name: "イベント",
            selector: "title",
            sortable: true,
            style: {},
        },
        {
            name: "詳細",
            selector: "detail",
            sortable: true,
        },
        {
            name: "日付時間",
            selector: "meeting_time",
            sortable: true,
        },
        {
            name: "アクション",
            sortable: false,
            selector: "null",
            cell: (d) => [
                <>
                    <button
                        className=" border bg-gradient-to-tr from-blue-500 to-blue-700  px-3 py-1 text-white rounded-2xl"
                        // onClick={() => handleDelete(d.id)}
                        onClick={() => {
                            setShowDeleteModal(true);
                            setId(d.id);
                        }}
                        id={d.id}
                    >
                        削除
                    </button>
                    <button
                        className=" border bg-gradient-to-tr from-pink-500 to-pink-700 px-3 py-1 text-white rounded-2xl"
                        onClick={(e) => {
                            handleEdit(d.id, d.title, d.detail, d.meeting_time);
                        }}
                    >
                        編集
                    </button>
                </>,
            ],
        },
    ];

    const tableData = {
        columns,
        data,
    };

    useEffect(() => {
        axios({
            url: base_url + "events/get",
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((res) => {
                console.log("Result", res.data.result);
                let events_array = res.data.result;
                events_array = events_array.reverse();
                setData(events_array);
                setFlag(true);
            })
            .catch((e) => {
                console.log("Error", e);
            });
    }, []);

    const handleEdit = (id, title, detail, meeting_time) => {
        let index = meeting_time.indexOf(")");
        let meeting_date = meeting_time.slice(0, index + 1);
        setMeetingDate(meeting_date);
        index = index + 2;
        let time = meeting_time.slice(index);
        setTime(time);
        setShowModal(true);
        setId(id);
        setTitle(title);
        setDetail(detail);
        setEvent(meeting_time);
    };

    const handleUpdateEvent = () => {
        setShowModal(false);
        let event1 = meetingDate + " " + time;
        let update_event = {
            id: id,
            title: title,
            detail: detail,
            event: event1,
        };
        axios.post(base_url + "events/update", { update_event }).then((res) => {
            toast.success("データが変更されました.", {
                position: "top-right",
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
            console.log(res.data.result);
            let events_array = res.data.result;
            events_array = events_array.reverse();
            setData(events_array);
        });
    };
    const handleDelete = () => {
        setShowDeleteModal(false);
        axios.post(base_url + "events/delete", { id }).then((res) => {
            let events_array = res.data.result;
            events_array = events_array.reverse();
            setData(events_array);
            toast.success("イベントが削除されました.", {
                position: "top-right",
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        });
    };
    const changeDate = (e) => {
        let year = moment(e).format("Y");
        let month = moment(e).format("M");
        let day = moment(e).format("D");
        let dayofweek = moment(e).day();
        console.log(dayofweek);
        let japanDay;
        switch (dayofweek) {
            case 0:
                japanDay = "(日)";
                break;
            case 1:
                japanDay = "(月)";
                break;
            case 2:
                japanDay = "(火)";
                break;
            case 3:
                japanDay = "(水)";
                break;
            case 4:
                japanDay = "(木)";
                break;
            case 5:
                japanDay = "(金)";
                break;
            case 6:
                japanDay = "(土)";
                break;
        }
        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }
        let event = month + "/" + day + japanDay + " " + "17:00~";
        let date = year + "-" + month + "-" + day;
        setDate(date);
        console.log(event);
        setDateState(e);
        setEvent(event);
    };
    const handleClick = () => {
        // setRows([]);
    };
    const changeEvent = (e) => {
        setEvent(e.target.value);
    };
    const handleAddEvent = () => {
        let event_info = {
            title: title,
            detail: detail,
            date: date,
            event: event,
        };

        axios.post(base_url + "events/add", { event_info }).then((res) => {
            toast.success("イベントが追加されました.", {
                position: "top-right",
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
            let member_array = res.data.result;
            member_array = member_array.reverse();
            setData(member_array);
        });
    };

    return (
        <>
            <div className=" pt-14 pb-14 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full"></div>
            </div>

            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <Card>
                            <CardBody>
                                <div className="flex flex-row">
                                    <div className="md:w-[30%]">
                                        <div className="md:mb-4">
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                placeholder="イベント"
                                                onChange={(e) => {
                                                    setTitle(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="md:mb-4">
                                            <Input
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                placeholder="詳細"
                                                onChange={(e) => {
                                                    setDetail(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="md:mb-4">
                                            <Calendar
                                                value={dateState}
                                                onChange={changeDate}
                                                className="w-full"
                                            />
                                        </div>

                                        <div className="md:mb-4">
                                            <Textarea
                                                color="lightBlue"
                                                outline={true}
                                                placeholder="日付時間"
                                                value={event}
                                                onChange={changeEvent}
                                            />
                                        </div>
                                        <Button
                                            color="lightBlue"
                                            buttonType="filled"
                                            size="regular"
                                            rounded={false}
                                            block={true}
                                            iconOnly={false}
                                            ripple="light"
                                            onClick={handleAddEvent}
                                        >
                                            イベント追加
                                            <Icon name="favorite" size="sm" />
                                        </Button>
                                    </div>
                                    <div className="md:w-[90%] ml-5 -mt-4">
                                        {flag != true ? (
                                            <></>
                                        ) : (
                                            <DataTableExtensions
                                                {...tableData}
                                                print={false}
                                                export={false}
                                                filterPlaceholder={
                                                    "フィルターテーブル"
                                                }
                                            >
                                                <DataTable
                                                    columns={columns}
                                                    data={data}
                                                    paginationComponentOptions={{
                                                        rowsPerPageText:
                                                            "ページ別表示件数",
                                                    }}
                                                    noHeader
                                                    defaultSortField="id"
                                                    defaultSortAsc={true}
                                                    pagination
                                                    highlightOnHover
                                                    dense
                                                />
                                            </DataTableExtensions>
                                        )}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>

            <Modal
                size="regular"
                active={showModal}
                toggler={() => setShowModal(false)}
            >
                <ModalHeader toggler={() => setShowModal(false)}>
                    <span className="text-gray-700">編集</span>
                </ModalHeader>
                <ModalBody>
                    <div className="flex flex-col w-96">
                        <div className="md:mb-4">
                            <Input
                                id="name"
                                type="text"
                                color="lightBlue"
                                size="regular"
                                outline={true}
                                placeholder="タイトル"
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                                value={title}
                            />
                        </div>

                        <div className="md:mb-4">
                            <Input
                                id="memo"
                                type="text"
                                color="lightBlue"
                                size="regular"
                                outline={true}
                                placeholder="詳細"
                                onChange={(e) => {
                                    setDetail(e.target.value);
                                }}
                                value={detail}
                            />
                        </div>

                        <div className="md:mb-4">
                            <div className="w-full relative h-11">
                                <input
                                    id="memo"
                                    type="text"
                                    placeholder=" "
                                    className="w-full h-full text-gray-800 leading-normal shadow-none outline-none focus:outline-none focus:ring-0 focus:text-gray-800 px-3 pt-2.5 pb-1.5 mt-input-outline-light-blue-500 border-gray-300 mt-input-outline bg-transparent border border-1 border-gray-300 rounded-lg focus:border-2 focus:border-light-blue-500 undefined undefined"
                                    value={meetingDate}
                                    disable="true"
                                    readOnly="true"
                                />
                                <label className="text-gray-400 absolute left-0 -top-1.5 w-full h-full false border-gray-300 pointer-events-none flex false leading-10 transition-all duration-300">
                                    日付
                                </label>
                            </div>
                        </div>

                        <div className="md:mb-4">
                            <Input
                                id="memo"
                                type="text"
                                color="lightBlue"
                                size="regular"
                                outline={true}
                                placeholder="時間"
                                onChange={(e) => {
                                    setTime(e.target.value);
                                }}
                                value={time}
                            />
                        </div>
                        <div className="w-full">
                            注意：既に登録されたイベントの日付は変更できません。　
                            <br />
                            日付を変更しようとする場合には登録されたイベントを削除してから改めて登録してください
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="green"
                        ripple="light"
                        onClick={handleUpdateEvent}
                    >
                        アップデート
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                size="regular"
                active={showDeleteModal}
                toggler={() => setShowDeleteModal(false)}
            >
                <ModalHeader toggler={() => setShowModal(false)}>
                    お知らせ
                </ModalHeader>
                <ModalBody>
                    <div className="flex flex-col justify-center items-center w-96">
                        <div className="md:mb-4">データを削除しますか？</div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="green" ripple="light" onClick={handleDelete}>
                        はい
                    </Button>
                </ModalFooter>
            </Modal>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}
