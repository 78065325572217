import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";
import Textarea from "@material-tailwind/react/Textarea";
import { useEffect, useState } from "react";
import moment from "moment";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import { React, useRef } from "react";
import { ScriptElementKindModifier, setTokenSourceMapRange } from "typescript";
import axios from "axios";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { ToastContainer, toast } from "react-toastify";
import "react-data-table-component-extensions/dist/index.css";
import "react-toastify/dist/ReactToastify.css";
import { base_url } from "../constant";

export default function Dashboard() {
    const [dateState, setDateState] = useState(new Date());
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState("");
    const [memo, setMemo] = useState("");
    const [data, setData] = useState([]);
    const [id, setId] = useState(0);
    const [flag, setFlag] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const columns = [
        {
            name: "お名前",
            selector: "name",
            sortable: true,
        },
        {
            name: "ノート",
            selector: "memo",
            sortable: true,
        },
        {
            name: "アクション",
            sortable: false,
            selector: "null",
            cell: (d) => [
                <>
                    <button
                        className=" border bg-gradient-to-tr from-blue-500 to-blue-700  px-3 py-1 text-white rounded-2xl"
                        onClick={() => {
                            setShowDeleteModal(true);
                            setId(d.id);
                        }}
                        id={d.id}
                    >
                        削除
                    </button>
                    <button
                        className=" border bg-gradient-to-tr from-pink-500 to-pink-700 px-3 py-1 text-white rounded-2xl"
                        onClick={(e) => {
                            handleEdit(d.id, d.name, d.memo);
                        }}
                    >
                        編集
                    </button>
                </>,
            ],
        },
    ];

    const tableData = {
        columns,
        data,
    };

    useEffect(() => {
        axios.get(base_url + "members/get").then((res) => {
            console.log(res.data.result);
            let member_array = res.data.result;
            member_array = member_array.reverse();
            setData(member_array);
            setFlag(true);
        });
    }, []);

    const handleUpdateUser = () => {
        setShowModal(false);
        const user = {
            id: id,
            name: name,
            memo: memo,
        };
        axios.post(base_url + "members/update", { user }).then((res) => {
            let member_array = res.data.result;
            member_array = member_array.reverse();
            setData(member_array);
        });
    };
    const handleDelete = () => {
        setShowDeleteModal(false);
        axios.post(base_url + "members/delete", { id }).then((res) => {
            let member_array = res.data.result;
            member_array = member_array.reverse();
            setData(member_array);
        });
        toast.success("イベントが削除されました.", {
            position: "top-right",
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    };

    const handleRegister = (e) => {
        console.log(name, memo);
        const user = {
            name: name,
            memo: memo,
        };
        axios.post(base_url + "members/add", { user }).then((res) => {
            toast.success("イベントが追加されました.", {
                position: "top-right",
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
            console.log(res.data.result);
            let member_array = res.data.result;
            member_array = member_array.reverse();
            setData(member_array);
        });
    };

    const handleEdit = (id, name, memo) => {
        setShowModal(true);
        console.log(id, name, memo);
        setId(id);
        setName(name);
        setMemo(memo);
    };

    return (
        <>
            <div className=" pt-14 pb-14 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full"></div>
            </div>

            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <Card>
                            <CardBody>
                                <div className="flex flex-row">
                                    <div className="md:w-[30%]">
                                        <div className="md:mb-4">
                                            <InputIcon
                                                id="name"
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                placeholder="お名前"
                                                iconFamily="material-icons"
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="md:mb-10">
                                            <InputIcon
                                                id="memo"
                                                type="text"
                                                color="lightBlue"
                                                size="regular"
                                                outline={true}
                                                placeholder="ノート"
                                                iconFamily="material-icons"
                                                onChange={(e) => {
                                                    setMemo(e.target.value);
                                                }}
                                            />
                                        </div>

                                        <Button
                                            color="lightBlue"
                                            buttonType="filled"
                                            size="regular"
                                            rounded={false}
                                            block={true}
                                            iconOnly={false}
                                            ripple="light"
                                            onClick={handleRegister}
                                        >
                                            メンバー追加
                                            <Icon name="favorite" size="sm" />
                                        </Button>
                                    </div>
                                    <div className="md:w-[90%] ml-5 -mt-4">
                                        {flag == false ? (
                                            <></>
                                        ) : (
                                            <DataTableExtensions
                                                {...tableData}
                                                print={false}
                                                export={false}
                                                filterPlaceholder={
                                                    "フィルターテーブル"
                                                }
                                            >
                                                <DataTable
                                                    columns={columns}
                                                    data={data}
                                                    paginationComponentOptions={{
                                                        rowsPerPageText:
                                                            "ページ別表示件数",
                                                    }}
                                                    noHeader
                                                    defaultSortField="id"
                                                    defaultSortAsc={true}
                                                    pagination
                                                    highlightOnHover
                                                    dense
                                                />
                                            </DataTableExtensions>
                                        )}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>

            <Modal
                size="regular"
                active={showModal}
                toggler={() => setShowModal(false)}
            >
                <ModalHeader toggler={() => setShowModal(false)}>
                    編集
                </ModalHeader>
                <ModalBody>
                    <div className="flex flex-col w-96">
                        <div className="md:mb-4">
                            <InputIcon
                                id="name"
                                type="text"
                                color="lightBlue"
                                size="regular"
                                outline={true}
                                placeholder="お名前"
                                iconFamily="material-icons"
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                                value={name}
                            />
                        </div>

                        <div className="md:mb-4">
                            <InputIcon
                                id="memo"
                                type="text"
                                color="lightBlue"
                                size="regular"
                                outline={true}
                                placeholder="ノート"
                                iconFamily="material-icons"
                                onChange={(e) => {
                                    setMemo(e.target.value);
                                }}
                                value={memo}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="green"
                        ripple="light"
                        onClick={handleUpdateUser}
                    >
                        アップデート
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                size="regular"
                active={showDeleteModal}
                toggler={() => setShowDeleteModal(false)}
            >
                <ModalHeader toggler={() => setShowModal(false)}>
                    お知らせ
                </ModalHeader>
                <ModalBody>
                    <div className="flex flex-col justify-center items-center w-96">
                        <div className="md:mb-4">データを削除しますか？</div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="green" ripple="light" onClick={handleDelete}>
                        はい
                    </Button>
                </ModalFooter>
            </Modal>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}
