import { React, useEffect, useState } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import AttendanceTable from "../components/AttendanceTable";
import circle from "../assets/img/circle.png";
import cross from "../assets/img/cross.png";
import triangle from "../assets/img/triangle.png";
import axios from "axios";
import { base_url } from "../constant";

export default function Home(props) {
    const [attendances, setAttendances] = useState([]);
    const [members, setMembers] = useState([]);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [current_month, setCurrentMonth] = useState("");

    useEffect(() => {
        axios.get(base_url + "attendance/getDefaultAttendances").then((res) => {
            console.log(res.data);
            let attendance_data = res.data.attendances;
            let members = res.data.members;
            let events = res.data.events;
            let current_month = res.data.current_month;
            current_month = parseInt(current_month);

            const groupByName = attendance_data.reduce((group, name) => {
                const { name_id } = name;
                group[name_id] = group[name_id] ?? [];
                group[name_id].push(name);
                return group;
            }, {});

            setAttendances(groupByName);
            setMembers(members);
            setEvents(events);
            setCurrentMonth(current_month);
            if (attendance_data.length != 0) {
                setLoading(true);
            }
        });
    }, []);
    return (
        <>
            <div className=" pt-14 pb-14 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full"></div>
            </div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <Card>
                            <CardBody>
                                <div className="flex  flex-row justify-between w-full overflow-hidden ">
                                    <div className="ml-10 mt-2">
                                        <span className=" text-[24px]  ">
                                            {current_month}月.
                                            {current_month + 1}月　スケジュール
                                        </span>
                                    </div>
                                    <div className="flex flex-row">
                                        <span className="mx-3">
                                            <img src={circle} />
                                            &nbsp;出席
                                        </span>
                                        <span className="mx-3">
                                            <img src={cross} />
                                            欠席
                                        </span>
                                        <span className="mx-3">
                                            <img src={triangle} />
                                            未回答
                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-col overflow-x-auto">
                                    <div className="">
                                        <div className="py-2 inline-block w-full">
                                            <div className="overflow-x-auto w-full">
                                                {loading == false ? (
                                                    <></>
                                                ) : (
                                                    <AttendanceTable
                                                        headers={events}
                                                        attendances={
                                                            attendances
                                                        }
                                                        members={members}
                                                    />
                                                )}
                                                <div className=" mb-28"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}
