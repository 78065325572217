import { useState } from "react";
import { NavLink } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import Icon from "@material-tailwind/react/Icon";
import H6 from "@material-tailwind/react/Heading6";
import logo from "../assets/img/logo.png";
export default function Sidebar() {
    const [showSidebar, setShowSidebar] = useState("-left-64");
    return (
        <>
            <AdminNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    <a
                        href="#"
                        target="_blank"
                        rel="noreferrer"
                        className="flex justify-center items-center text-center w-full"
                    >
                        {" "}
                        <img src={logo} className=" w-16 h-14 mr-3" />
                        <H6 color="gray" className="inline-block">
                            出席システム
                        </H6>
                    </a>
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />

                        <ul className="flex-col min-w-full flex list-none">
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/home"
                                    className={(navData) =>
                                        navData.isActive
                                            ? "flex items-center gap-4 text-sm  font-light px-4 py-3 rounded-lg bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                            : "flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    }
                                >
                                    <Icon name="settings" size="2xl" />
                                    出席状況
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/events"
                                    className={(navData) =>
                                        navData.isActive
                                            ? "flex items-center gap-4 text-sm  font-light px-4 py-3 rounded-lg bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                            : "flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    }
                                >
                                    <Icon name="settings" size="2xl" />
                                    イベント
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="/members"
                                    className={(navData) =>
                                        navData.isActive
                                            ? "flex items-center gap-4 text-sm font-light px-4 py-3 rounded-lg bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                            : "flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    }
                                >
                                    <Icon name="toc" size="2xl" />
                                    メンバ
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
