import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
    name: "admin",
    initialState: {
        isAdmin: JSON.parse(localStorage.getItem("isAdmin")),
    },
    reducers: {
        adminLogin: (state) => {
            localStorage.setItem("isAdmin", true);
            state.isAdmin = true;
        },
    },
});

export const { adminLogin } = adminSlice.actions;

export default adminSlice.reducer;
